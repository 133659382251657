import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const FullPageLoading = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ width: '100vw', height: '100vh' }}>
      <Grid item>
        <CircularProgress style={{ margin: 'auto' }} />
      </Grid>
    </Grid>
  );
}

export default FullPageLoading;