import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import MenuItem from './MenuItem';
import MenuFooter from './MenuFooter';

import { useTheme } from '@mui/material/styles';

const Menu = (props) => {
    const theme = useTheme();

    return (
      <Grid container direction="column" justifyContent="space-between" wrap="nowrap" style={{ minHeight: '100vh' }}>
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="center"
              style={{ padding: theme.spacing(3), paddingBottom: '3rem', marginLeft: 'auto', marginRight: 'auto', paddingLeft: '0.5rem' }}
          >
              <Grid item>
                  <img src="/assets/KALASAG Logo blue.png" style={{ height: '51px' }} alt="Logo"/>
              </Grid>
              <Grid item>
                  <Typography
                    style={{
                    fontWeight: 700,
                    fontSize: '1.5rem',
                    marginLeft: '0.525rem',
                    color: theme.palette.primary.main
                  }}
                  >
                      KALASAG
                  </Typography>
              </Grid>
          </Grid>

          <Grid container direction="column" alignItems="center" spacing={1} style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
            { React.Children.map(props.children, (child, i) => (
              child.type === MenuItem ? (
                <Grid item style={{ width: '100%' }} key={`menuItems-${i}`}>
                  {child}
                </Grid>
              ) : null
            )) }
          </Grid>
        </Grid>
        <Grid item>
          { React.Children.map(props.children, (child, i) => (
              child.type === MenuFooter ? (
                <Grid item style={{ width: '100%' }} key={`menuFooter-${i}`}>
                  {child}
                </Grid>
              ) : null
          )) }
        </Grid>
      </Grid>
    );
}

export default Menu;