import React from 'react';

import Button from '../Button';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useTheme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

const BaseButton = withStyles(() => ({
  root: {
    border: 0
  },
}))(Button);
const MenuItem = ({ icon, text, selected, children, onClick, hasSelected, logout }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(!hasSelected ?? true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  if (logout) {
    return (
      <BaseButton
        style={{ paddingTop: '1rem', paddingBottom: '1rem', width: '100%' }}
        color={'white'}
        onClick={onClick}
      >
        <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item style={{ color: theme.palette.primary.main }}>
              <ExitToAppIcon />
            </Grid>
            <Grid item xs>
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 400,
                        fontSize: 'clamp(0.8rem, 2vw, 1rem)',
                        color: theme.palette.primary.main,
                    }}
                >
                  Log Out
                </Typography>
            </Grid>
        </Grid>
      </BaseButton>
    );
  }

  return (
    <>
      <BaseButton
        style={{ paddingTop: '1rem', paddingBottom: '1rem', width: '100%' }}
        color={selected ? 'primary' : 'white'}
        onClick={children ? handleChange : onClick}
      >
        <Grid container direction="row" alignItems="center" wrap="nowrap">
            { icon ? (
              <Grid item style={{ color: selected ? 'white' : theme.palette.primary.main }}>
                { icon }
              </Grid>
            ) : null }
            <Grid item xs>
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 400,
                        fontSize: 'clamp(0.8rem, 2vw, 1rem)',
                        color: selected ? 'white' : theme.palette.primary.main,
                    }}
                >
                  { text }
                </Typography>
            </Grid>
            { children ? (
              <Grid item>
                  {expanded ? <ExpandMore style={{ color: selected ? 'white' : theme.palette.primary.main }} />
                  : <ExpandLess style={{ color: selected ? 'white' : theme.palette.primary.main  }}/>}
              </Grid>
            ) : null }
        </Grid>
      </BaseButton>
      { children ? (
        <Collapse in={!expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
          <Grid container direction="column" alignItems="center" wrap="nowrap">
              { React.Children.map(children, (child, i) => (
                <Grid item key={`menu-sub-items-${i}`} style={{ width: '100%' }}>
                  {child}
                </Grid>
              )) }
          </Grid>
        </Collapse>
      ) : null }
    </>
  )
};

export default MenuItem;
