import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthentication } from "../hooks/useAuthentication";
import { useAlert } from "../hooks/useAlert";

const ConditionalRedirect = ({
	children,
	isPrivate,
	kalasagerOnly,
	adminOnly,
	subscribersOnly,
	graduatingOnly,
	closed
}) => {
	const { loggedIn, user } = useAuthentication();
	const { pathname } = useLocation();
	const { triggerError } = useAlert();

	if (
		(isPrivate && !loggedIn)
	) {
		return <Navigate to="/login" />;
	}

	if (closed) {
		triggerError("This page is closed.");
		return <Navigate to="/" />;
	}

	if (
		(kalasagerOnly && (!user?.is_kalasager && !user?.is_admin)) ||
		(adminOnly && !user?.is_admin) ||
		(graduatingOnly && !user?.is_graduating)
	) {
		triggerError("Higher level access is required.");
		return <Navigate to="/dashboard" />;
	}

	if (
		(subscribersOnly && !user?.profile?.subscribed) ||
		((!user?.profile || !user?.logged_in_first_time) &&
			pathname !== "/login" &&
			pathname !== "/form-profile")
	) {
		return <Navigate to="/form-profile" />;
	}

	if (pathname === "/") {
		return <Navigate to="/login" />;
	}

	return React.Children.map(children, () => children);
};

const AppRoutes = ({
	component: Component,
	path,
	isPrivate,
	kalasagerOnly,
	adminOnly,
	graduatingOnly,
	closed,
	...rest
}) => {
	return (
		<ConditionalRedirect
			isPrivate={isPrivate}
			kalasagerOnly={kalasagerOnly}
			adminOnly={adminOnly}
			graduatingOnly={graduatingOnly}
			closed={closed}
		>
			<Component />
		</ConditionalRedirect>
	);
};

export default AppRoutes;
