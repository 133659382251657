import React from 'react';
import ReactDOM from 'react-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { AuthenticationProvider } from './hooks/useAuthentication';
import { AlertProvider } from './hooks/useAlert';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import BaseApp from './contexts/BaseApp';
import theme from './styles/theme';

ReactDOM.render(
  <>
    <CssBaseline />
    <CookiesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <AlertProvider>
                <AuthenticationProvider>
                  <BaseApp />
                </AuthenticationProvider>
              </AlertProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CookiesProvider>
  </>,
  document.getElementById('root')
);
