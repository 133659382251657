import API from '../utils/api';

const login = async (token) => {
  const user = await API.post('/login', {
    id_token: token
  });
  return user.data;
}

const getCurrentUser = async () => {
  const user = await API.get('/login/current');

  return user.data;
}

export { login, getCurrentUser };