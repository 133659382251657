import { createTheme, adaptV4Theme } from '@mui/material/styles';

// https://material-ui.com/customization/theming/

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#0D4DA1',
    },
    secondary: {
      main: '#A4AEC6',
    },
  },
  typography: {
    fontFamily: 'Lato'
  }
}));

export default theme;