import { useMemo, useState, createContext, useContext } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';

import { useCookies } from 'react-cookie';

import { GOOGLE_CLIENT_ID } from '../utils/constants';
import { login as customLogin, getCurrentUser } from '../adapters/authentication';

import { useAlert } from './useAlert';

export const AuthenticationContext = createContext();

const cookieOptions = {
	maxAge: 3600
};

export const AuthenticationProvider = ({ children }) => {
	const [cookies, setCookie, removeCookie] = useCookies(['user', 'token', 'g_token']);
	const [loading, setLoading] = useState(null);

	const { triggerSuccess, triggerError } = useAlert();

	const loggedIn = useMemo(() => {
		if (loading) {
			return null;
		}
		return !!cookies['user'];
	}, [cookies, loading]);

	const HOSTED_DOMAIN = 'addu.edu.ph';
	const COOKIE_POLICY = 'single_host_origin';

	const removeAllStorage = () => {
		removeCookie('token');
		removeCookie('user');
		removeCookie('g_token');
		setLoading(false);
	}

	const { signOut: logout, loaded: logoutLoaded } = useGoogleLogout({
    hostedDomain: HOSTED_DOMAIN,
		clientId: GOOGLE_CLIENT_ID,
		cookiePolicy: COOKIE_POLICY,
		onLogoutSuccess: () => {
			removeAllStorage();
		},
		onFailure: () => {
			removeAllStorage();
		}
  });

	const updateUser = async () => {
		try {
			const user = await getCurrentUser();

			setCookie('user', user, cookieOptions);
		} catch (err) {
			triggerError(err.response.data.error);
			logout();
		}
	}

	const onSuccess = async (response) => {
		const id_token = response.getAuthResponse().id_token;
		if (id_token === cookies['g_token']) {
			setLoading(false);
			return;
		}
		setCookie('g_token', id_token, cookieOptions);
		if (cookies['token'] && cookies['user']) {
			setLoading(false);
			return;
		}
		try {
			const user = await customLogin(id_token);

			setCookie('token', user.token, cookieOptions);
			setCookie('user', user, cookieOptions);
			triggerSuccess('Successfully logged in!');
		} catch (err) {
			logout();
			triggerError(err.response.data.error);
		}
		setLoading(false);
	}
	
	const { signIn: login, loaded } = useGoogleLogin({
		isSignedIn: true,
		hostedDomain: HOSTED_DOMAIN,
		clientId: GOOGLE_CLIENT_ID,
		cookiePolicy: COOKIE_POLICY,
		responseType: 'permission',
		onRequest: () => {
			setLoading(true);
		},
		onSuccess,
		onFailure: () => {
			removeAllStorage();
		}
	});

  return (
    <AuthenticationContext.Provider 
			value={{
				login,
				logout,
				updateUser,
				user: cookies['user'],
				token: cookies['token'],
				loggedIn,
				loaded: loaded && logoutLoaded
			}}
		>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  const auth = useContext(AuthenticationContext);
  return auth;
};

export default useAuthentication;