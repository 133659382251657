import React from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDrawer } from "../../hooks/useDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import Drawer from "@mui/material/Drawer";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import StorageIcon from "@mui/icons-material/Storage";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Menu from "../menu/Menu";
import MenuItem from "../menu/MenuItem";
import MenuFooter from "../menu/MenuFooter";

import { useAuthentication } from "../../hooks/useAuthentication";

const drawerWidth = 240;
const DEBUG = false;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
}));

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const classes = useStyles();
	const theme = useTheme();

	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

	const { isOpen, toggleDrawer } = useDrawer();
	const { user } = useAuthentication();

	if (location.pathname === "/login") {
		return <></>;
	}

	return (
		<>
			{user?.profile?.subscribed ||
			(user?.profile &&
				user?.logged_in_first_time &&
				(user?.is_kalasager || user?.is_admin)) ? (
				<Drawer
					className={classes.drawer}
					variant={isMdUp ? "permanent" : "temporary"}
					classes={{
						paper: classes.drawerPaper,
					}}
					open={isOpen}
					onClose={toggleDrawer}
					anchor="left"
				>
					<Menu>
						<MenuItem
							icon={<DashboardRoundedIcon />}
							text="Dashboard"
							onClick={() => navigate("/dashboard")}
							selected={location.pathname === "/dashboard"}
						/>
						<MenuItem
							icon={<ViewListRoundedIcon />}
							text="Forms"
							hasSelected={location.pathname.includes("form-")}
						>
							<MenuItem
								text="Basic Profile"
								onClick={() => navigate("/form-profile")}
								selected={location.pathname.includes("/form-profile")}
							/>
							{user.is_graduating || DEBUG ? (
								<>
									<MenuItem
										text="Form A"
										onClick={() => navigate("/form-a")}
										selected={location.pathname.includes("/form-a")}
									/>
									<MenuItem
										text="Form C"
										onClick={() => navigate("/form-c")}
										selected={location.pathname.includes("/form-c")}
									/>
									<MenuItem
										text="Form D"
										onClick={() => navigate("/form-d")}
										selected={location.pathname.includes("/form-d")}
									/>
								</>
							) : (
								<></>
							)}
						</MenuItem>
						{user.is_graduating || DEBUG ? (
							<MenuItem
								icon={<CameraAltIcon />}
								text="Pictorial"
								onClick={() => navigate("/pictorial")}
								selected={location.pathname === "/pictorial"}
							/>
						) : (
							<></>
						)}
						{user.is_kalasager || user.is_admin ? (
							[
								<MenuItem
									icon={<StorageIcon />}
									text="Database"
									onClick={() => navigate("/database")}
									selected={location.pathname === "/database"}
								/>,
							]
						) : (
							<></>
						)}
						<MenuItem
							icon={<PrivacyTipIcon />}
							text="Privacy Policy"
							onClick={() => navigate("/privacy-policy")}
							selected={location.pathname === "/privacy-policy"}
						/>
						<MenuFooter />
					</Menu>
				</Drawer>
			) : null}
			<span
				style={{
					width: "100%",
					height: theme.spacing(23),
					left: 0,
					top: 0,
					position: "absolute",
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url('/assets/addu.jpg')`,
					backgroundColor: "black",
					backgroundFit: "cover",
					backgroundPosition: "100% 43%",
				}}
			/>
		</>
	);
};

export default Header;
