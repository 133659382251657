import MaterialButton from '@mui/material/Button';

import withStyles from '@mui/styles/withStyles';

const BaseButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #F0F0F0',
    borderRadius: '0.8rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textTransform: 'none',
    color: '#A0A0A0',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    '&:active': {
      backgroundColor: '#fafafa',
    }
  },
}))(MaterialButton);

const PrimaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#24579E',
    },
    '&:active': {
      backgroundColor: '#24579E',
    }
  },
}))(BaseButton);

const Button = (props) => {
  const filteredProps = { ...props };
  delete filteredProps.color;

  if (props?.color === 'white') {
    return <BaseButton {...filteredProps} />
  }
  return <PrimaryButton {...filteredProps} />;
}

export default Button;
