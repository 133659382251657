import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import useAuthentication from '../../hooks/useAuthentication';
import MenuItem from './MenuItem';

const MenuFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, logout } = useAuthentication();

  const role = () => {
    if (user?.is_graduating) {
      return 'Student';
    }
    if (user?.is_kalasager) {
      return 'Kalasager';
    }
    if (user?.is_admin) {
      return 'Admin';
    }
  }

  return (
    <Grid container direction="column" style={{ marginTop: theme.spacing(2) }}>
      <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Divider style={{ width: `calc(100% - 4rem)` }} />
        </div>
      </Grid>
      { user?.is_kalasager || user?.is_admin ? (
        <Grid item style={{
          paddingLeft: theme.spacing(2), 
          paddingRight: theme.spacing(2), 
          marginBottom: theme.spacing(2)
        }}>
          <MenuItem
            icon={<SettingsIcon />}
            text="Settings"
            onClick={() => navigate('/settings')}
            selected={location.pathname === '/settings'}
          />
        </Grid>
      ) : null }
      <Grid item style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
        <MenuItem
          logout
          onClick={logout}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center"
          style={{
            paddingLeft: theme.spacing(4), 
            paddingBottom: theme.spacing(3),
            marginTop: theme.spacing(2)
          }}
        >
          <Grid item>
            <img src={`https://ui-avatars.com/api/?name=${user?.profile?.first_name}+${user?.profile?.last_name}`}
              style={{ height: '40px', width: 'auto', borderRadius: '10px' }} 
              alt="dp"/>
          </Grid>
          <Grid item>
            <Grid container  direction="column" justifyContent="center" alignItems="flex-start" 
              style={{ paddingLeft: '0.5rem', paddingBottom: '0.5rem' }}>
              <Grid item>
                <Typography
                  style={{
                  fontWeight: 700,
                  fontSize: '1rem',
                  marginLeft: '0.525rem',
                  color: theme.palette.primary.main
                }}
                >
                  { user?.profile?.first_name }
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  marginLeft: '0.525rem',
                  color: theme.palette.secondary.main
                }}
                >
                  {role()}
                </Typography>
              </Grid>
              
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MenuFooter;