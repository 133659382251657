import {
  useState, createContext, useContext, Children, forwardRef
} from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const AlertContext = createContext();

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [string, setString] = useState('');
  

  const triggerSuccess = (text) => {
    setType('success');
    setString(text);
    setOpen(true);
  };

  const triggerError = (text) => {
    setType('error');
    console.log(text);
    setString(/*text?.includes('The given data was invalid.') ? 'Some fields did not meet the requirements. Please check required fields!' : */text);
    setOpen(true);
  };

  const triggerWarning = (text) => {
    setType('warning');
    setString(text);
    setOpen(true);
  };

  const triggerInfo = (text) => {
    setOpen(true);
    setType('info');
    setString(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setString('');
    setOpen(false);
  };

  return (
    <AlertContext.Provider 
      value={{ 
        triggerSuccess,
        triggerError,
        triggerWarning,
        triggerInfo,
        handleClose,
        open,
        type,
        string,
      }}
    >
      <Snackbar 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
        open={open} 
        onClose={handleClose}
        autoHideDuration={3000}
        TransitionProps={{
          onExit: handleClose
        }}
      >
        <Alert onClose={handleClose} severity={type}>
          {string}
        </Alert>
      </Snackbar>
      { Children.map(children, () => children) }
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const alert = useContext(AlertContext);
  return alert;
};
