import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Pictorial = lazy(() => import("./pages/Pictorial"));
const Settings = lazy(() => import("./pages/Settings"));
const FormProfile = lazy(() => import("./pages/FormProfile"));
const FormA = lazy(() => import("./pages/FormA"));
const FormC = lazy(() => import("./pages/FormC"));
const FormD = lazy(() => import("./pages/FormD"));
const Database = lazy(() => import("./pages/Database"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Error404 = lazy(() => import("./pages/404"));

const routes = [
	{
		path: "/login",
		component: Login,
		isPrivate: false,
		subscribersOnly: false,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/dashboard",
		component: Dashboard,
		isPrivate: true,
		subscribersOnly: true,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/pictorial",
		component: Pictorial,
		isPrivate: true,
		subscribersOnly: true,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: true,
		closed: false,
	},
	{
		path: "/settings",
		component: Settings,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/form-profile/:id",
		component: FormProfile,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/form-a/:id",
		component: FormA,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/form-c/:id",
		component: FormC,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/form-d/:id",
		component: FormD,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
	},
	{
		path: "/form-profile",
		component: FormProfile,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/form-a",
		component: FormA,
		isPrivate: true,
		subscribersOnly: true,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: true,
		closed: false,
	},
	{
		path: "/form-c",
		component: FormC,
		isPrivate: true,
		subscribersOnly: true,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: true,
		closed: false,
	},
	{
		path: "/form-d",
		component: FormD,
		isPrivate: true,
		subscribersOnly: true,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: true,
		closed: false,
	},
	{
		path: "/database",
		component: Database,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: true,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/privacy-policy",
		component: PrivacyPolicy,
		isPrivate: true,
		subscribersOnly: false,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/",
		component: Home,
		isPrivate: false,
		subscribersOnly: false,
		kalasagerOnly: false,
		adminOnly: false,
		graduatingOnly: false,
		closed: false,
	},
	{
		path: "/*",
		component: Error404,
		isPrivate: false,
	},
];

export default routes;
