import React, { Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import CustomRoute from './CustomRoute';
import rawRoutes from '../routes';

import Loading from '../components/FullPageLoading';

const ContentRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {rawRoutes.map((route) => (
          <Route
            exact
            key={route.path}
            path={route.path}
            element={
              <CustomRoute
                {...route}
              />
            }
          />
        ))}
      </Routes>
    </Suspense>
  );
}

export default ContentRouter;