import axios from 'axios';
import { BACKEND_URL } from './constants';

const getCookie = (name) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length >= 2) return parts.pop().split(";").shift();
}

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    config.baseURL = BACKEND_URL;
    config.headers = { 
      'Authorization': `Bearer ${getCookie('token')}`,
    };
    return config;
  }, error => {
    Promise.reject(error);
});

export default axiosApiInstance;