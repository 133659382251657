import React from "react";

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ContentRouter from './ContentRouter';

import { DrawerProvider } from "../hooks/useDrawer";

import makeStyles from '@mui/styles/makeStyles';

import {
  useLocation,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 2,
  }
}));

const BaseApp = () => {
  const classes = useStyles();
  const { pathname, hash } = useLocation();

  React.useEffect(() => {
    // if not a hash link, scroll to top

    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]);

  return (
    <div className={classes.root}>
      <DrawerProvider>
        <Header />
        <ContentRouter />
        <Footer />
      </DrawerProvider>
    </div>
  );
}

export default BaseApp;